#skillSection {
  width: 100%;
}
.count {
  display: inline;
}
.spacer {
  margin: 3%;
}
.skillTitle {
  font-family: "Press Start 2P", cursive;
  text-align: center;
  padding: 15% 10%;
  font-size: 28px;
  letter-spacing: 2px;
  text-decoration: underline;
  color: #FFC107;
}
.skillList {
  color: white;
  text-align: center;
  font-family: "Press Start 2P", cursive;
  letter-spacing: 5px;
  font-size: 8px;
  letter-spacing: 0px;
}
.skillHeader {
  font-size: 20px;
  letter-spacing: 10px;
  padding: 4% 5%;
}
#skillSection p {
  font-size: 12px;
}

/* Desktop */
@media (min-width: 992px) {
  .skillTitle {
    letter-spacing: 10px;
    padding: 2% 10%;
    font-size: 28px;

  }
  .skillList {
    letter-spacing: 3px;
    
  }
  .skillHeader {
    letter-spacing: 15px;
  }
  #skillSection p {
    font-size: 12px;
  }
  .contributionChart {
    width: auto;
  }
}
