#preloaderSection {
  background-color: black;
  text-align: center;
  height: 77vh;
  background-size: cover;
  position: relative;
  color: white;
}

#copyright {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  font-size: 7px;
  font-family: 'Press Start 2P', cursive;
}

@keyframes blink {
  0% {
      opacity: .3;
  }
  50% {
      opacity: 0.7;
  }
  100% {
      opacity: 0;
  }
}
.titleText {
  font-size: 40px;
  font-weight: bolder;
  margin-top: 25%;
  margin-bottom: 60%;
  letter-spacing: 10px;
  font-family: 'Moirai One', cursive;
  display: block;
  height: 10%;
  color: #ffc107;
}

.startText {
letter-spacing: 2px;
font-size: 12px;
font-family: 'Press Start 2P', cursive;
animation: blink 
  1.5s linear infinite;
  color: white;
  text-decoration: none;
}
.startText :hover {
  color: #ffc107;
}

.ti-cursor {
  opacity: .4;
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  
  .titleText{
    font-size: 70px;
    margin-bottom: 40%;
  }
  
}

/* Landscapr */
@media only screen and (min-width: 480px) and (max-width: 1000px) and (orientation: landscape) {

  .titleText {
    margin-top: 5%;
    margin-bottom: 15%;
  }
}

/* Desktop */
@media (min-width: 992px) {
  #preloaderSection {
    height: 80vh;
  }

  #copyright {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    font-size: 7px;
    font-family: 'Press Start 2P', cursive;
    letter-spacing: 2px;
  }

  .titleText {
    font-size: 80px;
    letter-spacing: 30px;
    margin-top: 8%;
    margin-bottom: 18%;
  }
  .startText {
    letter-spacing: 5px;
    font-size: 12px;
    font-weight: bolder;

    }
  }