#statSection {
    width: 100%;
  }
.statBackground {
  background-color: white;
  width: 70%;
  text-align: center;
  border-radius: 0.375rem;
}
.statBox {
  padding: 3%;
}
.statTitle {
    font-family: "Press Start 2P", cursive;
    text-align: center;
    padding: 15% 10%;
    font-size: 26px;
    letter-spacing: 1px;
    text-decoration: underline;
    color: #FFC107;
 }
.octoKitLink {
  color: #6e5494 !important;
  font-size: 7px !important;
}
 .statText {
    font-size: 7px;
    font-family: "Press Start 2P", cursive;
 }

  
  /* Desktop */
  @media (min-width: 992px) {
    .statTitle {
      letter-spacing: 10px;
      padding: 2% 10%;
      font-size: 28px;
    }
    .statText{
      font-size: 12px;
    }
    .octoKitLink {
      color: #6e5494 !important;
      font-size: 12px !important;
    }
    .statBox {
      padding: 1%
    }
}
  