.imageHeadshot {
    height: 340px;
    width: 70%;
    margin-top: 15%;
}
.myName {
    font-family: 'Nosifer', cursive;
    color: #ffc107;
    font-size: 25px;
    }
.infoDiv{
    letter-spacing: 0px;
    text-align: left;
    margin-top: 20%;
    text-align: center;
    font-size: 12px;
    line-height: 50px;
}
.infoDivText {
    color: white;
    font-family: "Press Start 2P", cursive;
}
.imageDiv {
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.arrow {
    font-size: 20px;
    animation: blinker 1.5s linear infinite;
    color: #ffc107;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

/* Desktop */
@media (min-width: 992px) {

    .imageHeadshot {
        width: 50%;
        margin: 0;
        height: 70%;
        margin: 5%;
    }
    .myName {
        font-size: 25px
    }
    .imageDiv {
        text-align: left;
    }
    .infoDiv{
        letter-spacing: 3px;
        text-align: left;
        padding-left: 20%;
        line-height: 85px;
        margin-top: 4.5%;
    }

    .name {
        margin-left: -39px;
    }

}