body{
  height: 100%;
}
.App {
  background-color: black;
  height: 100%;
  width: 100%;
}
.mainDiv {
  display: none;
}
