#workSection{
    width: 100%;
}
#homeDepot {
    color: #F96302;
}

.workTitle {
    font-family: "Press Start 2P", cursive;
    text-align: center;
    padding: 5%;
    font-size: 28px;
    letter-spacing: 2px;
    text-decoration: underline;
    color: #FFC107;
}
.workDescription {
    color: white;
    width: 90%;
    font-size: 8px;
    margin: auto;
    font-family: "Press Start 2P", cursive;
    text-align: center;
    line-height: 15px;
  }

@media (min-width: 992px){
    .workTitle {
        letter-spacing: 10px;
        padding: 2% 10%;
        font-size: 28px;
      }

      .workDescription {
        width: 60%;
        font-size: 12px;
        line-height: 15px;
        padding-bottom: 5%;
      }
}